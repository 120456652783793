var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h4',{staticClass:"card-title mb-4"},[_vm._v("기본정보")]),_c('form',{attrs:{"action":"#"},on:{"submit":function($event){$event.preventDefault();return _vm.typeForm.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3 col-sm-3"},[_c('div',{staticClass:"form-group mb-4"},[_c('label',[_vm._v("쿠폰타입")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.typeform.type),expression:"typeform.type"}],staticClass:"form-control mg-r-5",class:{
                    'is-invalid': _vm.typesubmit && _vm.$v.typeform.type.$error
                  },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.typeform, "type", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"percent","selected":""}},[_vm._v("퍼센트")]),_c('option',{attrs:{"value":"mone","selected":""}},[_vm._v("금액")])]),(_vm.typesubmit && _vm.$v.typeform.type.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.type.required)?_c('span',[_vm._v("값을 선택해주세요.")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-3 col-sm-3"},[_c('div',{staticClass:"form-group mb-4"},[_c('label',[_vm._v("할인율(금액)")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.typeform.discountPrice),expression:"typeform.discountPrice"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.typesubmit && _vm.$v.typeform.discountPrice.$error
                  },attrs:{"type":"text"},domProps:{"value":(_vm.typeform.discountPrice)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.typeform, "discountPrice", $event.target.value)}}}),(_vm.typesubmit && _vm.$v.typeform.discountPrice.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.discountPrice.required)?_c('span',[_vm._v("값을 입력해주세요.")]):_vm._e(),(!_vm.$v.typeform.discountPrice.numeric)?_c('span',[_vm._v("숫자만 입력해주세요.")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-3 col-sm-3"},[_c('div',{staticClass:"form-group mb-4"},[_c('label',[_vm._v("수량")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.typeform.limitCount),expression:"typeform.limitCount"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.typesubmit && _vm.$v.typeform.limitCount.$error
                  },attrs:{"type":"text"},domProps:{"value":(_vm.typeform.limitCount)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.typeform, "limitCount", $event.target.value)}}}),(_vm.typesubmit && _vm.$v.typeform.limitCount.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.limitCount.required)?_c('span',[_vm._v("값을 선택해주세요.")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-3 col-sm-3"},[_c('div',{staticClass:"form-group mb-4"},[_c('label',[_vm._v("오픈여부")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.typeform.openYn),expression:"typeform.openYn"}],staticClass:"form-control mg-r-5",class:{
                    'is-invalid': _vm.typesubmit && _vm.$v.typeform.openYn.$error
                  },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.typeform, "openYn", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"Y","selected":""}},[_vm._v("Y")]),_c('option',{attrs:{"value":"N"}},[_vm._v("N")])]),(_vm.typesubmit && _vm.$v.typeform.openYn.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.openYn.required)?_c('span',[_vm._v("값을 선택해주세요.")]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3 col-sm-3"},[_c('div',{staticClass:"form-group mb-4"},[_c('label',[_vm._v("쿠폰번호(고유)")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.typeform.couponNumber),expression:"typeform.couponNumber"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.typesubmit && _vm.$v.typeform.couponNumber.$error
                  },attrs:{"type":"text"},domProps:{"value":(_vm.typeform.couponNumber)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.typeform, "couponNumber", $event.target.value)}}}),(_vm.typesubmit && _vm.$v.typeform.couponNumber.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.couponNumber.required)?_c('span',[_vm._v("값을 입력해주세요.")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group mb-4"},[_c('label',[_vm._v("쿠폰명")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.typeform.title),expression:"typeform.title"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.typesubmit && _vm.$v.typeform.title.$error
                  },attrs:{"type":"text"},domProps:{"value":(_vm.typeform.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.typeform, "title", $event.target.value)}}}),(_vm.typesubmit && _vm.$v.typeform.title.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.title.required)?_c('span',[_vm._v("값을 입력해주세요.")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-3 col-sm-3"},[_c('div',{staticClass:"form-group mb-4"},[_c('label',[_vm._v("유효기간")]),_c('date-picker',{attrs:{"format":"YYYY-MM-DD","first-day-of-week":1,"lang":"en","placeholder":"Select date"},model:{value:(_vm.typeform.limitDate),callback:function ($$v) {_vm.$set(_vm.typeform, "limitDate", $$v)},expression:"typeform.limitDate"}}),(_vm.typesubmit && _vm.$v.typeform.limitDate.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.limitDate.required)?_c('span',[_vm._v("값을 입력해주세요.")]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group mb-4"},[_c('label',[_vm._v("상세 내용")]),_c('CkeditorNuxtBasic',{attrs:{"config":_vm.config},model:{value:(_vm.typeform.content),callback:function ($$v) {_vm.$set(_vm.typeform, "content", $$v)},expression:"typeform.content"}})],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group mb-0"},[_c('div',[_c('button',{staticClass:"btn btn-primary mr-2",attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.typeform.couponId === '' ? '등록' : '수정')+" ")]),_c('button',{staticClass:"btn btn-secondary m-l-5 ml-1",attrs:{"type":"button"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" 취소 ")])])])])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }